import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const PolitikaKonfidenczialnostiPage = props => {
  const { location } = props
  return (
    <Layout path={location.pathname}>
      {
        // eslint-disable-next-line
        <SEO
          title="Политика конфиденциальности"
          description="Политика конфиденциальности сайта КаталикАвто. Данные, которые собираются при посещении. 
          Персональные данные. Неперсональные данные. Предоставление данных третьим лицам."
        />
      }
      <div className="mx-auto max-w-7xl overflow-hidden bg-white pt-20 text-lg text-gray-900 lg:pt-16">
        <section
          id="conf"
          aria-labelledby="conf-heading"
          className="mx-auto max-w-xl py-6 px-0 sm:py-24 md:px-12 lg:max-w-7xl"
        >
          <h1
            id="conf-heading"
            className="text-center text-4xl font-extrabold md:text-5xl"
          >
            Политика конфиденциальности
          </h1>
          <h2 className="pt-2 text-center text-xl font-light md:text-2xl">
            Данная политика конфиденциальности относится к сайту с доменным
            именем katalikauto.ru и его поддоменам. Страница содержит сведения о
            том, какую информацию администрация сайта или третьи лица могут
            получать, когда пользователь (вы) посещаете его.
          </h2>
          <div className="space-y-2 pt-2">
            <h4 className="text-lg font-semibold md:text-xl">
              Данные, которые собираются при посещении
            </h4>
            <h5 className="font-semibold">Персональные данные</h5>
            <p className="text-justify">
              Персональные данные при посещении сайта передаются пользователем
              добровольно, к ним могут относиться: имя, фамилия, отчество,
              номера телефонов, адреса электронной почты, адреса для доставки
              товаров или оказания услуг, реквизиты компании, которую
              представляет пользователь, должность в компании, которую
              представляет пользователь, аккаунты в социальных сетях, а также —
              прочие, заполняемые поля форм.
            </p>
            <p className="text-justify">
              Эти данные собираются в целях оказания услуг или продажи товаров,
              возможности связи с пользователем или иной активности пользователя
              на сайте, а также, чтобы отправлять пользователю информацию,
              которую он согласился получать.
            </p>
            <p className="text-justify">
              Мы не проверяем достоверность оставляемых данных и не гарантируем
              качественного исполнения заказов, оказания услуг или обратной
              связи с нами при предоставлении некорректных сведений.
            </p>
            <p className="text-justify">
              Данные собираются имеющимися на сайте формами для заполнения
              (например, регистрации, оформления заказа, подписки, оставления
              отзыва, вопроса, обратной связи и иными).
            </p>
            <p className="text-justify">
              Формы, установленные на сайте, могут передавать данные как
              напрямую на сайт, так и на сайты сторонних организаций (скрипты
              сервисов сторонних организаций).
            </p>
            <p className="text-justify">
              Данные могут собираться через технологию cookies (куки) как
              непосредственно сайтом, так и скриптами сервисов сторонних
              организаций. Эти данные собираются автоматически, отправку этих
              данных можно запретить, отключив cookies (куки) в браузере, в
              котором открывается сайт.
            </p>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default PolitikaKonfidenczialnostiPage
